import React from 'react'
import Layout from "../../components/Layout/Layout";
import WirelessFeatures from "../home/wirelessServices"

function WirelessServices() {
  return (
    <Layout>
    <div className="relative ">
      <div className="row flex about-us justify-center bg-[#080c3c] ">
        <h3 className="font-semibold text-my-headingT font-serif  text-white py-[30px]">
          Wireless Services
        </h3>
      </div>
      <WirelessFeatures/>
    </div>
  </Layout>
  )
}

export default WirelessServices