import React, { useEffect } from 'react'
import Layout from '../../components/Layout/Layout'
import Projects from '../home/projects'

export default function OurProjects() {
  useEffect(()=>{
    window.scrollTo(0,0)
    },[OurProjects])
  return (
    <Layout>
<div className="relative ">
        <div className="row flex about-us justify-center bg-[#080c3c] ">
          <h3 className="font-semibold text-my-headingT font-serif  text-white py-[30px]">
         Our Projects
          </h3>
        </div>
        
        <Projects/>
        </div>
    </Layout>
  )
}
