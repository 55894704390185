import React from "react";
import Layout from "../../components/Layout/Layout";
import TechnicalFeatures from "./technicalFeatures";

const TechincalServices = () => {
  return (
    <Layout>
      <div className="relative ">
        <div className="row flex about-us justify-center bg-[#080c3c] ">
          <h3 className="font-semibold text-my-headingT font-serif  text-white py-[30px]">
            Technical Services
          </h3>
        </div>
        <TechnicalFeatures />
      </div>
    </Layout>
  );
};

export default TechincalServices;
