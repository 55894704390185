import React, { useState } from "react";
import { motion } from "framer-motion";
import network from "../../assets/network.gif";
import tower from "../../assets/tower.gif";
import technical from "../../assets/technical.gif";
import { Link } from "react-router-dom";
import ButtonArrowImg from "../../assets/button-arrow-base.png";

const Features = ({ heading }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(!show);
  };

  return (
    <div
      className={`container mx-auto px-3 md:px-12 ${
        heading ? "" : "md:py-[60px] py-[30px]"
      }`}
    >
      <div
        className={`${
          heading ? "block" : "hidden"
        } text-[22px] md:text-[26px] lg:text-3xl xl:text-[35px] font-serif font-bold text-center`}
      >
        Our Services
      </div>
      <div className="lg:flex lg:justify-between justify-center items-center flex-wrap text-center py-4">
        {/* Technical Services Card */}
        <div className="lg:h-[300px] bg-white md:w-full w-full lg:w-[30%] shadow-[0px_4px_20px_5px_#00000024] hover:shadow-[0px_10px_20px_10px_#00000024] min-w-[280px] text-black rounded-[22px] border-none md:mx-2 my-2 relative lg:flex lg:flex-col lg:items-center lg:justify-center card lg:before:bg-[#ff366b] group">
          {/*          <motion.img
            className="absolute"
            src={circle}
            style={{
              position: "absolute",
              top: "-10%",
              left: "0%",
              transform: "translate(-50%, -50%)",
              zIndex: 5,
            }}
            initial={{ y: 300 }}
            animate={{ x: 200, opacity: 1 }}
            transition={{
              duration: 8,
              repeat: Infinity,
              repeatType: "reverse",
              ease: "linear",
            }}
          />
          <motion.img
            className="absolute"
            src={square}
            style={{
              position: "absolute",
              top: "40%",
              left: "20%",

              transform: "translate(-50%, -50%)",
              zIndex: 5,
            }}
            animate={{ x: "50%" }}
            initial={{ x: "-100%", y: "-100%" }}
            transition={{
              duration: 4,
              repeat: Infinity,
              repeatType: "reverse",
              ease: "linear",
            }}
          />
          <motion.img
            className="absolute"
            src={tri}
            style={{
              position: "absolute",
              top: "10%",
              left: "50%",

              transform: "translate(-50%, -50%)",
              zIndex: 5,
            }}
            animate={{ y: "150%" }}
            initial={{ x: "-150%", y: "-150%" }}
            transition={{
              duration: 6,
              repeat: Infinity,
              repeatType: "reverse",
              ease: "linear",
            }}
          />
          <motion.img
            className="absolute"
            src={plus}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",

              transform: "translate(-50%, -50%)",
              zIndex: 5,
            }}
            animate={{ x: "100%", y: "100%" }}
            initial={{ x: "-100%", y: "-100%" }}
            transition={{
              duration: 6,
              repeat: Infinity,
              repeatType: "reverse",
              ease: "linear",
            }}
          /> */}
          <div className="py-5 flex justify-center relative px-2">
            {/*  <div className="origin-center rotate-45 h-[80px]  relative w-[80px] bg-[#FF366B] rounded-[20px] my-design before:border-2 before:border-[#FF366B] before:h-[5em] before:top-[0px] before:left-[5px] flex justify-center items-center">
              <img
                src={Worker}
                className="z-10 rotate-[315deg] absolute top-[20%] h-[50px]"
              />    </div>*/}
            <h2 className="font-extrabold text-2xl lg:whitespace-nowrap tracking-wider">
              TECHNICAL SERVICES
            </h2>
          </div>
          <div className="flex justify-center">
            <img src={technical} className="w-[150px]" />
          </div>
          <Link
            to="/technical-services"
            className="absolute bottom-2 left-2 md:left-auto opacity-0 group-hover:opacity-100 group-hover:translate-x-0 transform translate-x-full transition-all duration-500 bg-[#ff366b] text-white font-bold py-2 px-4 rounded-full z-10"
          >
            Show More
          </Link>
        </div>

        {/* Wireless Services Card */}
        <div className="lg:h-[300px] bg-white md:w-full w-full lg:w-[30%] shadow-[0px_4px_20px_5px_#00000024] hover:shadow-[0px_10px_20px_10px_#00000024] min-w-[280px] text-black rounded-[22px] border-none md:mx-2 my-2 relative lg:flex lg:flex-col lg:items-center lg:justify-center card lg:before:bg-[#F3D337] group">
          {/*   <motion.img
            className="absolute"
            src={circle}
            style={{
              position: "absolute",
              top: "0%",
              left: "0%",
              transform: "translate(-50%, -50%)",
              zIndex: 5,
            }}
            initial={{ y: 300 }}
            animate={{ x: 200, opacity: 1 }}
            transition={{
              duration: 8,
              repeat: Infinity,
              repeatType: "reverse",
              ease: "linear",
            }}
          />
          <motion.img
            className="absolute"
            src={square}
            style={{
              position: "absolute",
              top: "40%",
              left: "20%",

              transform: "translate(-50%, -50%)",
              zIndex: 5,
            }}
            animate={{ x: "50%" }}
            initial={{ x: "-100%", y: "-100%" }}
            transition={{
              duration: 4,
              repeat: Infinity,
              repeatType: "reverse",
              ease: "linear",
            }}
          />
          <motion.img
            className="absolute"
            src={tri}
            style={{
              position: "absolute",
              top: "10%",
              left: "50%",

              transform: "translate(-50%, -50%)",
              zIndex: 5,
            }}
            animate={{ y: "150%" }}
            initial={{ x: "-150%", y: "-150%" }}
            transition={{
              duration: 6,
              repeat: Infinity,
              repeatType: "reverse",
              ease: "linear",
            }}
          />
          <motion.img
            className="absolute"
            src={plus}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",

              transform: "translate(-50%, -50%)",
              zIndex: 5,
            }}
            animate={{ x: "100%", y: "100%" }}
            initial={{ x: "-100%", y: "-100%" }}
            transition={{
              duration: 6,
              repeat: Infinity,
              repeatType: "reverse",
              ease: "linear",
            }}
          /> */}
          <div className="py-5 flex justify-center relative px-2">
            {/*  <div className="origin-center rotate-45 h-[80px] w-[80px] bg-[#1A28F6] rounded-[20px]  my-design before:border-[#1A28F6] before:border-2 before:h-[5em] before:top-[0px] before:left-[5px] flex justify-center items-center ">
              <img
                src={CellTower}
                className="rotate-[315deg] z-10 absolute top-[20%]  h-[48px]"
              />
            </div> */}
            <h2 className="font-extrabold text-2xl lg:whitespace-nowrap tracking-wider">
              WIRELESS SERVICES
            </h2>
          </div>
          <div className="flex justify-center">
            <img src={tower} className="w-[180px]" />
          </div>
          <Link
            to="/wirelessServices"
            className="absolute bottom-2 left-2 md:left-auto opacity-0 group-hover:opacity-100 group-hover:translate-x-0 transform translate-x-full transition-all duration-500 bg-[#F3D337] text-white font-bold py-2 px-4 rounded-full z-10"
          >
            Show More
          </Link>
        </div>

        {/* Wireline Services Card */}
        <div className="lg:h-[300px] bg-white md:w-full w-full lg:w-[30%] shadow-[0px_4px_20px_5px_#00000024] hover:shadow-[0px_10px_20px_10px_#00000024] min-w-[280px] text-black rounded-[22px] border-none md:mx-2 my-2 relative lg:flex lg:flex-col lg:items-center lg:justify-center card lg:before:bg-[#1a28f6] group">
          {/*   <motion.img
            className="absolute"
            src={circle}
            style={{
              position: "absolute",
              top: "0%",
              left: "0%",
              transform: "translate(-50%, -50%)",
              zIndex: 5,
            }}
            initial={{ y: 300 }}
            animate={{ x: 200, opacity: 1 }}
            transition={{
              duration: 8,
              repeat: Infinity,
              repeatType: "reverse",
              ease: "linear",
            }}
          />
          <motion.img
            className="absolute"
            src={square}
            style={{
              position: "absolute",
              top: "40%",
              left: "20%",

              transform: "translate(-50%, -50%)",
              zIndex: 5,
            }}
            animate={{ x: "50%" }}
            initial={{ x: "-100%", y: "-100%" }}
            transition={{
              duration: 4,
              repeat: Infinity,
              repeatType: "reverse",
              ease: "linear",
            }}
          />
          <motion.img
            className="absolute"
            src={tri}
            style={{
              position: "absolute",
              top: "10%",
              left: "50%",

              transform: "translate(-50%, -50%)",
              zIndex: 5,
            }}
            animate={{ y: "150%" }}
            initial={{ x: "-150%", y: "-150%" }}
            transition={{
              duration: 6,
              repeat: Infinity,
              repeatType: "reverse",
              ease: "linear",
            }}
          />
          <motion.img
            className="absolute"
            src={plus}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",

              transform: "translate(-50%, -50%)",
              zIndex: 5,
            }}
            animate={{ x: "100%", y: "100%" }}
            initial={{ x: "-100%", y: "-100%" }}
            transition={{
              duration: 6,
              repeat: Infinity,
              repeatType: "reverse",
              ease: "linear",
            }}
          /> */}
          <div className="py-5 flex justify-center relative px-2">
            {/*  <div className="origin-center rotate-45 h-[80px] w-[80px] bg-[#F3D337] rounded-[20px]  my-design before:border-[#F3D337] before:border-2 before:h-[5em] before:top-[0px] before:left-[5px] flex justify-center items-center">
              <img
              src={Technology}
              className="rotate-[315deg] z-10 absolute top-[20%] h-[48px]"
              />
              </div> */}
            <h2 className="font-extrabold text-2xl lg:whitespace-nowrap tracking-wider">
              WIRELINE SERVICES
            </h2>
          </div>
          <div className="flex justify-center">
            <img src={network} className="w-[150px]" />
          </div>
          <Link
            to="/wirelineservices"
            className="absolute bottom-2 left-2 md:left-auto opacity-0 group-hover:opacity-100 group-hover:translate-x-0 transform translate-x-full transition-all duration-500 bg-[#1a28f6] text-white font-bold py-2 px-4 rounded-full z-10"
          >
            Show More
          </Link>
        </div>
      </div>

      {/* Conditional Button with Animation */}
      {heading ? (
        <div className="flex align-middle justify-center">
          <div className="relative">
            <button
              className="bg-[#FF366B] text-[16px] text-white font-normal lg:px-[80px] sm:py-[15px] sm:px-[40px] py-[10px] px-[30px] border-none h-full rounded-[15px] tracking-wide RedbuttonAnim"
              onMouseEnter={handleShow}
              onMouseLeave={handleShow}
            >
              More
            </button>
            <motion.div
              className="absolute top-0 right-[-75px]"
              initial={{ y: 0 }}
              animate={{ y: -10 }}
              transition={{
                duration: 5,
                repeat: Infinity,
                repeatType: "reverse",
              }}
            >
              <img src={ButtonArrowImg} alt="button arrow base" />
            </motion.div>

            {show ? (
              <div className="absolute bottom-[60px] lg:-left-[30px] sm:-left-[70px] -right-[70px] w-64 text-lg text-gray-500 transition-opacity duration-300 bg-white lg:opacity-50 opacity-80 border rounded-lg shadow-sm dark:text-gray-400 dark:bg-gray-800 text-center z-10">
                <div className="px-3 py-2 relative">
                  <p>This Panel Is Under Development</p>
                  <div className="absolute opacity-80 -bottom-[5px] left-[120px] right-[120px] border-collapse rotate-180">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height={5}
                      width={10}
                    >
                      <path d="m5.016 0-2.51 2.5L0 4.999 5.016 5l5.017-.001L7.525 2.5 5.016 0z" />
                    </svg>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Features;
