import React from "react";
import Layout from "../../components/Layout/Layout";
import WirelineServices from "../home/wirelineService";

function WirelineServicesPage() {
  return (
    <Layout>
      <div className="relative ">
        <div className="row flex about-us justify-center bg-[#080c3c] ">
          <h3 className="font-semibold text-my-headingT font-serif  text-white py-[30px]">
            Wireline Services
          </h3>
        </div>
        <WirelineServices />
      </div>
    </Layout>
  );
}

export default WirelineServicesPage;
