import React, { useRef, useState } from "react";
import Formborder from "../../assets/contact-border.png";
import formImg from "../../assets/contact-us.png";
import emailjs from "@emailjs/browser";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { motion } from "framer-motion";
import ArrowBaseTwo from "../../assets/arrow-base-two.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'

const ContactUs = () => {
  //Email
  const sendEmail = (values) => {
    const sendEmailPromise = new Promise((resolve, reject) => {
      emailjs
        .sendForm(
          "service_ingguf5",
          "template_lhcz0p1",
          form.current,
          "6fYqUNF8qeE3q4LxR" 
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });

    toast.promise(sendEmailPromise, {
      pending: "Form Submitting...",
      success:
        ("Form Submitted",
        {
          autoClose: 4000,
          render: "Form Submit",
          pauseOnHover: false,
        }),
      error:
        ("Sry! Form is not Submitted",
        {
          autoClose: 4000,
          render: "Sry! Form not Submitted",
          pauseOnHover: false,
        }),
    });

    /*   sendEmailPromise.then(
        result => {
         console.log(result.text);
        }).catch(error => {
          console.log(error.text);
        }
      ); */

    form.current.reset();
  };
  const form = useRef();

  //yup validation
  const signupSchema = Yup.object().shape({
    fullName: Yup.string()
      .min(4, "*Too short!")
      .max(20, "*Too Long!")
      .required("*Required"),
    email: Yup.string().email("*Invalid Email").required("*Required"),
    phoneNo: Yup.string() 
      .min(8, "*Enter Valid Number")   
      .required("*Required"),
    company: Yup.string()
      .min(8, "*Too short")
      .max(20, "*Too Long")
      .required("*Required"),
  });

  return (
    <>
      <div className="w-full p-10 flex flex-col-reverse lg:flex-row gap-5 container mx-auto pb-20">
        <div className="p-10 lg:w-1/2 flex justify-center max-[425px]:hidden ">
          <div className="relative z-1 w-[100%] ">
            <div className="image_wrapper ">
              <img
                src={formImg}
                alt=" not found"
                className="w-[70%]  mx-auto"
              />
            </div>
            <img
              src={Formborder}
              alt=" not found"
              className="absolute -z-50 left-10 top-9  w-[70%] max-md:hidden "
            />
          </div>
        </div>

        <div className="lg:w-1/2 flex flex-col gap-5">
          <span className="font-semibold text-xl lg:text-3xl">
            Get In Touch
          </span>
          <Formik
            initialValues={{
              fullName: "",
              email: "",
              phoneNo: "+1",
              message: "",
              company: "",
            }}
            validationSchema={signupSchema}
            onSubmit={(values) => {
              sendEmail(values);
            }}>
            {({ errors, touched, handleSubmit, handleChange, values ,setFieldValue }) => (
              <Form
                className="flex flex-col gap-5 "
                ref={form}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}>
                <Field
                  className={`w-full p-5  ${
                    errors.fullName && touched.fullName
                      ? "border-2 border-red-800"
                      : "border border-gray-300"
                  } rounded-[0.5em]`}
                  type="text"
                  name="fullName"
                  placeholder="Full Name"
                  onChange={handleChange}
                  value={values.fullName}
                />
                {errors.fullName && touched.fullName ? (
                  <span className="flex items-center text-[0.6rem] text-[red]">
                    {errors.fullName}
                  </span>
                ) : null}
                <div className="flex flex-col gap-5 justify-between md:flex-row lg:gap-5">
                  <Field
                    className={`md:w-[49%] p-5  ${
                      errors.email && touched.email
                        ? "border-2 border-red-800"
                        : "border border-gray-300"
                    } rounded-[0.5em]`}
                    type="email"
                    name="email"
                    placeholder="Email Address"
                  />
                  {errors.email && touched.email ? (
                    <span className="flex items-center text-[0.6rem] text-[red]">
                      {errors.email}
                    </span>
                  ) : null}
                  <Field 
                    name="phoneNo"
                    className={`md:w-[49%] h-[100%] p-5  ${
                      errors.phoneNo && touched.phoneNo
                        ? "border-2 border-red-800"
                        : "border border-gray-300"
                    } rounded-[0.5em]`}>
                      {({field ,form:{errors}})=>(
                  <>     
                  <PhoneInput
                  {...field}
                  countryCodeEditable={false}
                  enableSearch={true}
                  disableSearchIcon
                  country={'ca'}
                  preferredCountries={['us','ca']}
                  inputStyle={{width:"100%", height:"100%" ,padding:"20px 50px" , border: errors.phoneNo && touched.phoneNo
                  ? "2px solid rgb(153 27 27)"
                  : "1px solid rgb(209 213 219)" , borderRadius:"0.5em"}}
                
                  value={values.phoneNo}
                  onChange={(value)=>setFieldValue('phoneNo', value)}
                       dropdownStyle={{width:"240px", }}
                       buttonStyle={{border:"none",padding:"0px" , margin:"4px" ,backgroundColor:"transparent", ":hover":{ background:"transparent",border:"none"
                       }}}
                 inputProps={{name:'phoneNo'}}
                  />
                   {errors.phoneNo && touched.phoneNo ? (
                    <span className="flex items-center text-[0.6rem] text-[red]">
                      {errors.phoneNo}
                    </span>

                  ) : null}
                  </>)}
                  </Field>
                 
                </div>
                <Field
                  className={`w-full p-5  ${
                    errors.company && touched.company
                      ? "border-2 border-red-800"
                      : "border border-gray-300"
                  } rounded-[0.5em]`}
                  type="text"
                  name="company"
                  placeholder="Company Name"
                  onChange={handleChange}
                  value={values.company}
                />
                {errors.company && touched.company ? (
                  <span className="flex items-center text-[0.6rem] text-[red]">
                    {errors.company}
                  </span>
                ) : null}
                <Field
                  className="w-full p-5 h-40 rounded-[0.5em] border border-gray-300"
                  as="textarea"
                  name="message"
                  rows={6}
                  placeholder="Your Message"></Field>
                <div className="relative inline-block sm:w-1/3">
                  <button
                    className="bg-[#FF366B] text-white font-normal py-[15px] px-[40px] h-full w-full border-none  rounded-[15px] buttonAnim before:border-[#FF366B]"
                    type="submit">
                    contact us
                  </button>
                  <motion.div
                    className="absolute bottom-[-20px] right-[-120px] max-[440px]:-right-[10px] max-[440px]:-bottom-4 max-[440px]:w-[80px]"
                    initial={{ y: 0 }}
                    animate={{ y: 20 }}
                    transition={{
                      duration: 5,
                      repeat: Infinity,
                      repeatType: "reverse",
                    }}>
                    <img src={ArrowBaseTwo} alt="button arrow base" />
                  </motion.div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={1}
          limit={2}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          bodyClassName="toastify-css"
        />
      </div>
    </>
  );
};

export default ContactUs;
