import React, { useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import Features from "../home/features";
import Services from "../home/services";

const OurServices = () => {
useEffect(()=>{
window.scrollTo(0,0)
},[OurServices])
  return (
    <Layout>
      <div className="relative ">
        <div className="row flex about-us justify-center bg-[#080c3c] ">
          <h3 className="font-semibold text-my-headingT font-serif  text-white py-[30px]">
            Our services
          </h3>
        </div>

        <Features />
        {/* <Services /> */}
      </div>
    </Layout>
  );
};

export default OurServices;
