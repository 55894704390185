import React from "react";
import FooterLogo from "../../../assets/logoBlack.png";
import { motion } from "framer-motion";
import Circle from "../../../assets/shape-circle.png";
import Square from "../../../assets/shape-square.png";
import Triangle from "../../../assets/shape-triangle.png";
import FooterTitle from "../../../assets/footer-title-bg.png";
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import { ImLocation } from "react-icons/im";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="{/*dark:bg-gray-900*/} bg-[white] mx-auto border-t-2 px-3 md:px-12">
      <div className="container mx-auto w-full  ">
        <div className="grid md:grid-cols-2  xl:grid-cols-3 p-[24px] justify-center items-center lg:items-stretch">
          <div className="relative md:row-span-3 md:pt-8 ">
            <motion.img
              className="absolute"
              src={Circle}
              style={{
                position: "absolute",
                top: "0%",
                left: "70%",
                right: "0%",
                transform: "translate(-50%, -50%)",
                zIndex: 5,
              }}
              alt="circle"
              initial={{ y: 200, x: -100 }}
              animate={{ y: 80, opacity: 1 }}
              transition={{
                duration: 8,
                repeat: Infinity,
                repeatType: "reverse",
                ease: "linear",
              }}
            />

            <motion.img
              className="absolute"
              alt="Triangle"
              src={Triangle}
              style={{
                position: "absolute",
                top: "5%",
                left: "10%",
                right: "0%",
                transform: "translate(-50%, -50%)",
                zIndex: 5,
              }}
              animate={{ y: "150%" }}
              initial={{ x: "-150%", y: "-50%" }}
              transition={{
                duration: 6,
                repeat: Infinity,
                repeatType: "reverse",
                ease: "linear",
              }}
            />

            <motion.img
              className="absolute"
              src={Square}
              style={{
                position: "absolute",
                top: "10%",
                left: "70%",
                right: "0%",
                transform: "translate(-50%, -50%)",
                zIndex: 5,
              }}
              alt="Square"
              animate={{ x: "50%" }}
              initial={{ x: "-100%", y: "-150%" }}
              transition={{
                duration: 4,
                repeat: Infinity,
                repeatType: "reverse",
                ease: "linear",
              }}
            />
            <div className="relative">
              <img
                className="md:h-[82px] sm:h-16 h-12 relative md:m-0 m-auto z-10  "
                src={FooterLogo}
                alt="header logo"
              />
              {/* <div className="absolute sm:-top-[80px] -top-[60px] md:-left-[40px] sm:left-[90px] left-[60px]">
              <img
                src={logoBg}
                className="md:w-[300px] sm:w-[160px] w-[160px] md:h-[240px]"
                alt="logoBg"
              />
          
            </div> */}
            </div>
            <p className="text-gray-600 py-2  lg:text-[18px] sm:text-[16px] text-[10px] font-serif max-[430px]:text-center font-bold font-base uppercase">
              Wireless and Wireline Telecom Services
            </p>
          </div>
          <div className="xl:text-center max-[430px]:hidden">
            <h2 className="py-2 text-xl font-semibold text-black relative">
              Useful Link
              <img
                src={FooterTitle}
                className="absolute top-6 xl:left-[170px] "
                alt="footerTitle"
              ></img>
            </h2>
            <ul className="text -gray-600 dark:text-gray-600 font-base pt-2 ">
              <li className=" hover:underline">
                <Link to="/about">About us</Link>
              </li>
              <li className="hover:underline py-2">
                <Link to="/services">Our Services</Link>
              </li>
              <li className=" hover:underline ">
                <Link to="/our-projects">Our Projects</Link>
              </li>

              <li className=" hover:underline py-2">
                <Link to="/health-safety"> Health & Safety</Link>
              </li>
              <li className="pb-2 hover:underline">
                <Link to="/contact"> Contact us</Link>
              </li>
            </ul>
          </div>
          {/* <div>
            <h2 className="mb-6 text-xl font-semibold text-white  relative">
              Pages
              <img
                src={FooterTitle}
                className="absolute top-3"
                alt="footer title"></img>
            </h2>
            <ul className="text-gray-500 dark:text-gray-400 font-base">
              <li className="mb-4">
                <a href="/" className="hover:underline">
                  How to Do
                </a>
              </li>
              <li className="mb-4">
                <a href="/" className="hover:underline">
                  Pricing Plan
                </a>
              </li>
              <li className="mb-4">
                <a href="/" className="hover:underline">
                  Faq
                </a>
              </li>
              <li className="mb-4">
                <a href="/" className="hover:underline">
                  Blog ClassNameic
                </a>
              </li>
            </ul>
          </div> */}
          <div>
            <h2 className="py-2 text-xl font-semibold text-black relative ">
              Contact Us
              <img
                src={FooterTitle}
                className="absolute top-6"
                alt="footer title"
              ></img>
            </h2>
            <p className="text-gray-600 flex items-center pt-2 max-[430px]:pt-0">
              <ImLocation />
              <p className="pl-2">
                F9-1585 Britannia Rd E, Mississauga, ON L4W 2M4
              </p>
            </p>
            <a
              className="text-gray-600 py-2 flex items-center"
              href="mailto:support@pbxtechnologies.com"
            >
              <FaEnvelope />
              <p className="pl-2">support@pbxtechnologies.com</p>
            </a>
            <a
              className="pb-2 text-gray-600 flex items-center"
              href="tel:+1647-335-3901"
            >
              <FaPhoneAlt /> <p className="pl-2">+1647-335-3901</p>
            </a>
            <ul className="text-gray-600 dark:text-gray-600 font-base max-[430px]:justify-center flex gap-3 py-2">
              <li className=" my-designS">
                <a
                  href="https://www.facebook.com/pbxtechnologies?mibextid=LQQJ4d"
                  className="text-gray-600 hover:text-gray-900 dark:hover:text-white"
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span className="sr-only">Facebook page</span>
                </a>
              </li>
              <li className=" my-designS">
                <a
                  href="https://www.instagram.com/pbxtechnologies/"
                  className="text-gray-600 hover:text-gray-900 dark:hover:text-white"
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span className="sr-only">Instagram page</span>
                </a>
              </li>
              <li className=" my-designS ">
                <a
                  href="https://www.linkedin.com/company/pbx-technologies-com-inc/"
                  className="text-gray-600 hover:text-gray-900 dark:hover:text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-linkedin"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                  </svg>
                  <span className="sr-only">Linked in</span>
                </a>
              </li>
              {/*        <li className="mb-4 my-designS">
                <a
                  href="/"
                  className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true">
                    <path
                      fill-rule="evenodd"
                      d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span className="sr-only">GitHub account</span>
                </a>
              </li> */}
            </ul>
          </div>
        </div>
        <div className="pb-4 flex justify-center items-center text-center text-gray-400">
          Copyright ©2024 All Rights Reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
