import React from "react";
import { useState } from "react";
import { Dialog, Popover } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import HeaderLogo from "../../../assets/logoBlack.png";
import logoBg from "../../../assets/logoBgF.png";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  /* const location = useLocation();
  const updated = location.pathname;
 */
  return (
    <>
      <div className=" relative z-0 border-none ">
        <nav
          className="mx-auto container flex items-center justify-between p-6 lg:px-8 z-50 "
          aria-label="Global">
          <div className="flex lg:flex-1 relative ">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only ">Your Company</span>
              <img
                className="md:h-[55px]  h-12 w-auto relative "
                src={HeaderLogo}
                alt="header logo"
              />
{/* <div className="absolute -top-[60px] md:-left-[40px] -left-[25px] -z-10 md:w-[230px] w-[160px] " >
              <img src={logoBg} className="w-[230px] md:h-[170px]" alt="logoBg"/>
              </div> */}
            </Link>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-black"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
              <span className="sr-only">Open Close menu</span>
              {!mobileMenuOpen ? (
                <Bars3Icon className="h-6 w-6" />
              ) : (
                <XMarkIcon className="h-6 w-6" />
              )}
            </button>
          </div>
          <Popover.Group className="hidden lg:flex lg:gap-x-8 justify-center  items-center">
            <Link
              to="/"
              className="text-large font-extrabold leading-6 text-gray-900  headHover">
              Home
            </Link>

            <Link
              to="/about"
              className="text-large  font-extrabold leading-6 text-gray-900 headHover">
              About us
            </Link>
            <Link
              to="/services"
              className="text-large font-extrabold leading-6 text-gray-900 headHover">
              Our Services
            </Link>
            <Link
              to="/our-projects"
              className="text-large font-extrabold leading-6 text-gray-900 headHover">
              Our Projects
            </Link>
            <Link
              to="/health-safety"
              className="text-large font-extrabold leading-6 text-gray-900 headHover">
              Health & Safety
            </Link>
            <Link
              to="/contact"
              className="text-large  font-extrabold leading-6 text-gray-900 headHover">
              Contact Us
            </Link>
          </Popover.Group>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}>
          {/* <div className="fixed inset-0 z-10" /> */}
          <Dialog.Panel className=" right-0  w-full  overflow-y-auto  px-6 py-6  absolute top-20 bg-[#080c3c] z-10">
            <div className="flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <Link
                    to="/"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50 hover:text-black">
                    Home
                  </Link>
                  <Link
                    to="/about"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50 hover:text-black">
                    About us
                  </Link>
                  <Link
                    to="/services"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50 hover:text-black">
                    Our Services
                  </Link>
                  <Link
                    to="/our-projects"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50 hover:text-black">
                    Our Projects
                  </Link>

                  <Link
                    to="/health-safety"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50 hover:text-black">
                    Health & Safety
                  </Link>
                  {/*  <Link
                    to="/blog"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50 hover:text-black">
                    Blog
                  </Link> */}
                  <Link
                    to="/contact"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50 hover:text-black">
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </div>
    </>
  );
};

export default Header;
