import React, { useEffect } from "react";
import "./index.css";
import { GiWireCoil } from "react-icons/gi";
import { motion } from "framer-motion";
import circle from "../../assets/shape-circle.png";
import square from "../../assets/shape-square.png";
import tri from "../../assets/shape-triangle.png";
import { BsDatabaseFillGear } from "react-icons/bs";
import { MdMiscellaneousServices } from "react-icons/md";
import { GrInstall, GrVmMaintenance } from "react-icons/gr";
import { SiWire } from "react-icons/si";
import { RiUserVoiceFill } from "react-icons/ri";
import { FaNetworkWired } from "react-icons/fa";

const WirelineServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [WirelineServices]);

  return (
    <section>
      <motion.img
        className="absolute"
        src={circle}
        style={{
          position: "absolute",
          top: "0%",
          left: "0%",
          transform: "translate(-50%, -50%)",
          zIndex: 5,
        }}
        initial={{ y: 300 }}
        animate={{ x: 100, opacity: 1 }}
        transition={{
          duration: 8,
          repeat: Infinity,
          repeatType: "reverse",
          ease: "linear",
        }}
      />
      <motion.img
        className="absolute"
        src={square}
        style={{
          position: "absolute",
          top: "40%",
          left: "20%",

          transform: "translate(-50%, -50%)",
          zIndex: 5,
        }}
        animate={{ x: "50%" }}
        initial={{ x: "-100%", y: "-100%" }}
        transition={{
          duration: 4,
          repeat: Infinity,
          repeatType: "reverse",
          ease: "linear",
        }}
      />
      <motion.img
        className="absolute"
        src={tri}
        style={{
          position: "absolute",
          top: "10%",
          left: "30%",

          transform: "translate(-50%, -50%)",
          zIndex: 5,
        }}
        animate={{ x: "150%" }}
        initial={{ x: "-150%", y: "-100%" }}
        transition={{
          duration: 6,
          repeat: Infinity,
          repeatType: "reverse",
          ease: "linear",
        }}
      />
      <motion.img
        className="absolute"
        src={circle}
        style={{
          position: "absolute",
          top: "0%",
          left: "70%",
          right: "0%",
          transform: "translate(-50%, -50%)",
          zIndex: 5,
        }}
        initial={{ y: 200, x: -100 }}
        animate={{ y: 140, opacity: 1 }}
        transition={{
          duration: 8,
          repeat: Infinity,
          repeatType: "reverse",
          ease: "linear",
        }}
      />
      <motion.img
        className="absolute"
        src={tri}
        style={{
          position: "absolute",
          top: "10%",
          left: "70%",
          right: "0%",
          transform: "translate(-50%, -50%)",
          zIndex: 5,
        }}
        animate={{ y: "150%" }}
        initial={{ x: "-150%", y: "-150%" }}
        transition={{
          duration: 6,
          repeat: Infinity,
          repeatType: "reverse",
          ease: "linear",
        }}
      />
      <motion.img
        className="absolute"
        src={square}
        style={{
          position: "absolute",
          top: "40%",
          left: "70%",
          right: "0%",
          transform: "translate(-50%, -50%)",
          zIndex: 5,
        }}
        animate={{ x: "50%" }}
        initial={{ x: "-100%", y: "-100%" }}
        transition={{
          duration: 4,
          repeat: Infinity,
          repeatType: "reverse",
          ease: "linear",
        }}
      />
      <div className="max-w-7xl mx-auto md:px-32 px-2 md:py-12">
        <div className="md:mb-12">
          <p className="text-base font-bold text-slate-500 text-center ">
            PBX Technologies offers a comprehensive suite of wireline services
            designed to support and enhance network infrastructure. Our
            expertise spans from essential installations to specialized
            solutions, ensuring reliable and efficient operations.
          </p>
        </div>
        <div className="rounded-[22px] shadow-[0px_4px_20px_5px_#00000024] md:p-10 p-5  mt-5">
          <div className="mb-8">
            <div className="flex items-start gap-2 md:gap-6">
              <div
                className="origin-center rotate-45 sm:h-[50px] sm:w-[50px] h-[40px] w-[40px] bg-[#1A28F6] sm:rounded-[20px] rounded-[15px]
                 my-design before:border-[#1A28F6] before:border-2 before:top-[0px] before:left-[5px] before:h-[2.8em]  before:sm:h-[3.4em] shrink-0"
              >
                <FaNetworkWired
                  className="absolute z-10 rotate-[315deg] top-[20%] sm:left-[25%] left-[20%]"
                  size={25}
                  color="#ffffff"
                />
              </div>
              <div className="pl-4">
                <h3 className="text-xl font-bold mb-1">Copper Work:</h3>
                <p className="text-slate-500 ">
                  Installation, maintenance, and repair of copper cabling
                  systems to support reliable data and voice communications.
                </p>
              </div>
            </div>
          </div>

          <div className="mb-8">
            <div className="flex items-start gap-2 md:gap-6">
              <div
                className="origin-center rotate-45 sm:h-[50px] sm:w-[50px] h-[40px] w-[40px] bg-[#1A28F6] sm:rounded-[20px] rounded-[15px]
                 my-design before:border-[#1A28F6] before:border-2 before:top-[0px] before:left-[5px] before:h-[2.8em]  before:sm:h-[3.4em] shrink-0"
              >
                <GiWireCoil
                  className="absolute z-10 rotate-[315deg] top-[20%] left-[20%] sm:left-[25%] sm:top-[25%]"
                  size={25}
                  color="#ffffff"
                />
              </div>
              <div className="pl-4">
                <h3 className="text-xl font-bold mb-1">
                  Fiber Run, Splicing, and Troubleshooting:
                </h3>
                <p className="text-slate-500 text-sm sm:text-base">
                  Expert handling of fiber optic cabling, including
                  installation, splicing, and issue resolution to ensure optimal
                  performance.
                </p>
              </div>
            </div>
          </div>

          <div className="mb-8">
            <div className="flex items-start gap-2 md:gap-6">
              <div
                className="origin-center rotate-45 sm:h-[50px] sm:w-[50px] h-[40px] w-[40px] bg-[#1A28F6] sm:rounded-[20px] rounded-[15px]
                 my-design before:border-[#1A28F6] before:border-2 before:top-[0px] before:left-[5px] before:h-[2.8em]  before:sm:h-[3.4em] shrink-0"
              >
                <SiWire
                  className="absolute z-10 rotate-[315deg] top-[20%] sm:left-[25%] left-[20%]"
                  size={25}
                  color="#ffffff"
                />
              </div>
              <div className="pl-4">
                <h3 className="text-xl font-bold mb-1">
                  Fiber-to-the-Home (FTTH) Installations:
                </h3>
                <p className="text-slate-500">
                  Deployment of FTTH systems to deliver high-speed internet
                  directly to residential and commercial properties.
                </p>
              </div>
            </div>
          </div>
          <div className="mb-8">
            <div className="flex items-start gap-2 md:gap-6">
              <div
                className="origin-center rotate-45 sm:h-[50px] sm:w-[50px] h-[40px] w-[40px] bg-[#1A28F6] sm:rounded-[20px] rounded-[15px]
                 my-design before:border-[#1A28F6] before:border-2 before:top-[0px] before:left-[5px] before:h-[2.8em]  before:sm:h-[3.4em] shrink-0"
              >
                <MdMiscellaneousServices
                  className="absolute z-10 rotate-[315deg] top-[20%] sm:left-[25%] left-[20%]"
                  size={25}
                  color="#ffffff"
                />
              </div>
              <div className="pl-4">
                <h3 className="text-xl font-bold mb-1">
                  Directional Drilling and Hydrovac Services:
                </h3>
                <p className="text-slate-500">
                  Non-destructive excavation techniques for precise installation
                  and maintenance of underground utilities.
                </p>
              </div>
            </div>
          </div>
          <div className="mb-8">
            <div className="flex items-start gap-2 md:gap-6">
              <div
                className="origin-center rotate-45 sm:h-[50px] sm:w-[50px] h-[40px] w-[40px] bg-[#1A28F6] sm:rounded-[20px] rounded-[15px]
                 my-design before:border-[#1A28F6] before:border-2 before:top-[0px] before:left-[5px] before:h-[2.8em]  before:sm:h-[3.4em] shrink-0"
              >
                <RiUserVoiceFill
                  className="absolute z-10 rotate-[315deg] top-[20%] sm:left-[25%] left-[20%]"
                  size={25}
                  color="#ffffff"
                />
              </div>
              <div className="pl-4">
                <h3 className="text-xl font-bold mb-1">
                  Voice and Data Cabling:
                </h3>
                <p className="text-slate-500">
                  Comprehensive solutions for structured cabling systems that
                  support voice and data communication needs.
                </p>
              </div>
            </div>
          </div>
          <div className="mb-8">
            <div className="flex items-start gap-2 md:gap-6">
              <div
                className="origin-center rotate-45 sm:h-[50px] sm:w-[50px] h-[40px] w-[40px] bg-[#1A28F6] sm:rounded-[20px] rounded-[15px]
                 my-design before:border-[#1A28F6] before:border-2 before:top-[0px] before:left-[5px] before:h-[2.8em]  before:sm:h-[3.4em] shrink-0"
              >
                <GrVmMaintenance
                  className="absolute z-10 rotate-[315deg] top-[20%] sm:left-[25%] left-[20%]"
                  size={25}
                  color="#ffffff"
                />
              </div>
              <div className="pl-4">
                <h3 className="text-xl font-bold mb-1">
                  Maintenance and Support:
                </h3>
                <p className="text-slate-500">
                  Ongoing support and maintenance services to ensure the
                  reliability and efficiency of network operations.
                </p>
              </div>
            </div>
          </div>
          <div className="mb-8">
            <div className="flex items-start gap-2 md:gap-6">
              <div
                className="origin-center rotate-45 sm:h-[50px] sm:w-[50px] h-[40px] w-[40px] bg-[#1A28F6] sm:rounded-[20px] rounded-[15px]
                 my-design before:border-[#1A28F6] before:border-2 before:top-[0px] before:left-[5px] before:h-[2.8em]  before:sm:h-[3.4em] shrink-0"
              >
                <GrInstall
                  className="absolute z-10 rotate-[315deg] top-[20%] sm:left-[25%] left-[20%]"
                  size={25}
                  color="#ffffff"
                />
              </div>
              <div className="pl-4">
                <h3 className="text-xl font-bold mb-1">
                  Telecom Equipment Installation:
                </h3>
                <p className="text-slate-500">
                  Installation of essential telecom equipment, including PBX
                  systems and VoIP solutions.
                </p>
              </div>
            </div>
          </div>

          <div className="mb-8">
            <div className="flex items-start gap-2 md:gap-6">
              <div
                className="origin-center rotate-45 sm:h-[50px] sm:w-[50px] h-[40px] w-[40px] bg-[#1A28F6] sm:rounded-[20px] rounded-[15px]
                 my-design before:border-[#1A28F6] before:border-2 before:top-[0px] before:left-[5px] before:h-[2.8em]  before:sm:h-[3.4em] shrink-0"
              >
                <FaNetworkWired
                  className="absolute z-10 rotate-[315deg] top-[20%] sm:left-[25%] left-[20%]"
                  size={25}
                  color="#ffffff"
                />
              </div>
              <div className="pl-4">
                <h3 className="text-xl font-bold mb-1">WAN & LAN Solutions:</h3>
                <p className="text-slate-500">
                  Design and management of Wide Area Networks (WAN) and Local
                  Area Networks (LAN) to support organizational connectivity.
                </p>
              </div>
            </div>
          </div>
          <div className="mb-8">
            <div className="flex items-start gap-2 md:gap-6">
              <div
                className="origin-center rotate-45 sm:h-[50px] sm:w-[50px] h-[40px] w-[40px] bg-[#1A28F6] sm:rounded-[20px] rounded-[15px]
                 my-design before:border-[#1A28F6] before:border-2 before:top-[0px] before:left-[5px] before:h-[2.8em]  before:sm:h-[3.4em] shrink-0"
              >
                <BsDatabaseFillGear
                  className="absolute z-10 rotate-[315deg] top-[20%] sm:left-[25%] left-[20%]"
                  size={25}
                  color="#ffffff"
                />
              </div>
              <div className="pl-4">
                <h3 className="text-xl font-bold mb-1">
                  Data Center Management:
                </h3>
                <p className="text-slate-500">
                  Services for managing data centers, including rack and stack,
                  power, and cooling solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WirelineServices;
