import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./pages/home";
import AboutUs from "./pages/aboutUs";
import OurServices from "./pages/our-services";
import HealthSafety from "./pages/health&safety";
import ContactUs from "../src/pages/contactUs/index";
import OurProjects from "./pages/ourProjects";
import WirelineServicesPage from "./pages/wirelineServices";
import WirelessServices from "./pages/wirelessServices";
import TechnicalServices from "./pages/technicalServices";

export default function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/about",
      element: <AboutUs />,
    },
    {
      path: "/services",
      element: <OurServices />,
    },
    {
      path: "/health-safety",
      element: <HealthSafety />,
    },
    {
      path: "/our-projects",
      element: <OurProjects />,
    },
    {
      path: "/contact",
      element: <ContactUs />,
    },
    {
      path: "/wirelessServices",
      element: <WirelessServices />,
    },
    {
      path: "/wirelineservices",
      element: <WirelineServicesPage />,
    },
    {
      path: "/technical-services",
      element: <TechnicalServices />,
    },
  ]);
  return <RouterProvider router={router} />;
}
