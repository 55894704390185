import React from "react";
import { GrInstallOption } from "react-icons/gr";
import { FaRegCheckCircle } from "react-icons/fa";
import { IoIosLink } from "react-icons/io";
import { motion } from "framer-motion";
import Circle from "../../assets/shape-circle.png";
import Square from "../../assets/shape-square.png";
import Triangle from "../../assets/shape-triangle.png";

const TechnicalFeatures = ({ heading }) => {
  return (
    <div
      className={`container mx-auto px-3 md:px-14 ${
        heading ? "" : "md:py-[60px] py-[30px]"
      }`}
    >
      <div
        className={`${
          heading ? "block" : "hidden"
        } text-[22px] md:text-[26px] lg:text-3xl xl:text-[35px] font-serif font-bold text-center`}
      >
        Our Services
      </div>
      <section>
        <motion.img
          className="absolute"
          src={Circle}
          style={{
            position: "absolute",
            top: "0%",
            left: "0%",
            transform: "translate(-50%, -50%)",
            zIndex: 5,
          }}
          initial={{ y: 300 }}
          animate={{ x: 100, opacity: 1 }}
          transition={{
            duration: 8,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "linear",
          }}
        />
        <motion.img
          className="absolute"
          src={Square}
          style={{
            position: "absolute",
            top: "40%",
            left: "20%",

            transform: "translate(-50%, -50%)",
            zIndex: 5,
          }}
          animate={{ x: "50%" }}
          initial={{ x: "-100%", y: "-100%" }}
          transition={{
            duration: 4,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "linear",
          }}
        />
        <motion.img
          className="absolute"
          src={Triangle}
          style={{
            position: "absolute",
            top: "10%",
            left: "30%",

            transform: "translate(-50%, -50%)",
            zIndex: 5,
          }}
          animate={{ x: "150%" }}
          initial={{ x: "-150%", y: "-100%" }}
          transition={{
            duration: 6,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "linear",
          }}
        />

        <motion.img
          className="absolute"
          src={Circle}
          style={{
            position: "absolute",
            top: "0%",
            left: "70%",
            right: "0%",
            transform: "translate(-50%, -50%)",
            zIndex: 5,
          }}
          initial={{ y: 200, x: -100 }}
          animate={{ y: 140, opacity: 1 }}
          transition={{
            duration: 8,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "linear",
          }}
        />

        <motion.img
          className="absolute"
          src={Triangle}
          style={{
            position: "absolute",
            top: "10%",
            left: "70%",
            right: "0%",
            transform: "translate(-50%, -50%)",
            zIndex: 5,
          }}
          animate={{ y: "150%" }}
          initial={{ x: "-150%", y: "-150%" }}
          transition={{
            duration: 6,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "linear",
          }}
        />

        <motion.img
          className="absolute"
          src={Square}
          style={{
            position: "absolute",
            top: "40%",
            left: "70%",
            right: "0%",
            transform: "translate(-50%, -50%)",
            zIndex: 5,
          }}
          animate={{ x: "50%" }}
          initial={{ x: "-100%", y: "-100%" }}
          transition={{
            duration: 4,
            repeat: Infinity,
            repeatType: "reverse", 
            ease: "linear",
          }}
        />
        <div className="flex flex-col gap-y-4">
          <p className="text-lg px-4 sm:px-9 flex flex-nowrap text-slate-500 text-center ">
            PBX Technologies Inc. provides expert technicians to install and
            optimize network equipment and software across various environments,
            including central offices, Data Centers, and Customer Premises. We
            specialize in Optimization, Pre-Assembly, Field, and Managed
            Services.
          </p>
          <div className="flex justify-center items-center w-full">
            <div className=" bg-white h-auto w-full md:w-[80%] shadow-[0px_4px_20px_5px_#00000024] hover:shadow-[0px_10px_20px_10px_#00000024] min-w-[280px] text-black rounded-[22px] border-none md:mx-2 my-2  relative lg:flex lg:items-center lg:justify-center lg:gap-10 card ">
              <div className="lg:block flex flex-col lg:flex-nowrap justify-center align-initial text-center pb-8 ">
                <div className=" lg:h-auto bg-white  w-full  min-w-[280px] text-black rounded-[22px]   border-none    relative pt-5 card ">
                  <div className="flex flex-col gap-y-3">
                    <div className="pt-4 px-8 text-slate-500 text-base">
                      <div className="flex gap-x-4">
                        <div
                          className="origin-center rotate-45 sm:h-[50px] sm:w-[50px] !h-[40px] !w-[40px] bg-[#FF366B] sm:rounded-[20px] !rounded-[15px]
              my-design  before:border-[#FF366B] before:border-2 before:top-[0px] before:left-[5px] before:!h-[2.8em]  before:sm:h-[3.4em] shrink-0"
                        >
                          <GrInstallOption
                            className="absolute z-10 rotate-[315deg] top-[17%]
                    left-[19%] md:!left-[18%]"
                            size={25}
                            color="#ffffff"
                          />
                        </div>
                        <div className="flex flex-col gap-2">
                          <h2 className="font-semibold text-start text-xl text-black lg:whitespace-nowrap tracking-wider ">
                            Installation:
                          </h2>
                          <p className="  lg:text-start  text-left tracking-wider custom-li-icon">
                            We handle antennas, racks, power systems, fiber
                            panels, and more for seamless connectivity.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="px-8 text-slate-500 text-base">
                      <div className="flex  gap-x-4">
                        <div
                          className="origin-center rotate-45 sm:h-[50px] sm:w-[50px] !h-[40px] !w-[40px] bg-[#FF366B] sm:rounded-[20px] !rounded-[15px]
              my-design  before:border-[#FF366B] before:border-2 before:top-[0px] before:left-[5px] before:!h-[2.8em]  before:sm:h-[3.4em] shrink-0"
                        >
                          <FaRegCheckCircle
                            className="absolute z-10 rotate-[315deg] top-[17%]
                    left-[19%] md:!left-[18%]"
                            size={25}
                            color="#ffffff"
                          />
                        </div>
                        <div className="flex flex-col gap-2">
                          <h2 className="font-semibold text-start text-xl text-black lg:whitespace-nowrap tracking-wider ">
                            Testing:
                          </h2>
                          <p className="lg:text-start text-left tracking-wider custom-li-icon">
                            Offering comprehensive tests like BER, RFC, SWEEP
                            Lines/cables, OTDR, PIM, and call testing to ensure
                            optimal performance.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="px-8 text-slate-500 text-base ">
                      <div className="flex  gap-x-4 ">
                        <div
                          className="origin-center rotate-45 sm:h-[50px] sm:w-[50px] !h-[40px] !w-[40px] bg-[#FF366B] sm:rounded-[20px] !rounded-[15px]
              my-design  before:border-[#FF366B] before:border-2 before:top-[0px] before:left-[5px] before:!h-[2.8em]  before:sm:h-[3.4em] shrink-0"
                        >
                          <IoIosLink
                            className="absolute z-10 rotate-[315deg] top-[17%]
                    left-[19%] md:!left-[18%]"
                            size={25}
                            color="#ffffff"
                          />
                        </div>
                        <div className="flex flex-col gap-2">
                          <div className="font-semibold text-start text-xl text-black lg:whitespace-nowrap tracking-wider w-full text-wrap">
                            Commissioning / Integration:
                          </div>
                          <p className="lg:text-start lg:decoration-black text-left  lg:list-disc tracking-wider custom-li-icon ">
                            We excel in 1G to 5G+ commissioning, cutovers,
                            integration, decommissioning, and detailed final
                            handover reports focused on network reliability and
                            innovation.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TechnicalFeatures;
