import React from "react";
import Layout from "../../components/Layout/Layout";
import AboutCompany from "../home/aboutCompany";
import AboutCompanyBottom from "../home/aboutCompanyBottom";

const AboutUs = () => {
  return (
    <Layout>
      <div className="relative pb-[30px]">
        <div className="row flex about-us justify-center bg-[#080c3c] ">
          <h3 className="font-semibold text-my-headingT font-serif  text-white py-[30px]">
            About us
          </h3>
        </div>
        <AboutCompany heading={true}/>
       {/*  <AboutCompanyBottom heading={true}/> */}
      </div>
    </Layout>
  );
};

export default AboutUs;
