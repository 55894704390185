import React from "react";
import Layout from "../../components/Layout/Layout";
import HealthSafetyHome from "../home/HealthSafetyHome";
import Images from "../home/images";

const HealthSafety = () => {
  return (
    <Layout>
      <div className="relative  ">
        <div className="row flex about-us justify-center bg-[#080c3c] ">
          <h3 className="font-semibold text-my-headingT font-serif  text-white py-[30px]">
            Health and Safety
          </h3>
        </div>
        <HealthSafetyHome heading={false} headingS={true} />
        <Images />
      </div>
    </Layout>
  );
};

export default HealthSafety;
