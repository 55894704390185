import React from "react";
import { TbAntenna } from "react-icons/tb";
import { LuRadioTower } from "react-icons/lu";
import { GrHostMaintenance } from "react-icons/gr";
import { LuConstruction } from "react-icons/lu";
import { GrInstall } from "react-icons/gr";
import { motion } from "framer-motion";
import circle from "../../assets/shape-circle.png";
import square from "../../assets/shape-square.png";
import tri from "../../assets/shape-triangle.png";

function WirelessFeatures() {
  return (
    <section className="relative overflow-hidden">
      <motion.img
        className="absolute"
        src={circle}
        style={{
          position: "absolute",
          top: "0%",
          left: "0%",
          transform: "translate(-50%, -50%)",
          zIndex: 5,
        }}
        initial={{ y: 300 }}
        animate={{ x: 100, opacity: 1 }}
        transition={{
          duration: 8,
          repeat: Infinity,
          repeatType: "reverse",
          ease: "linear",
        }}
      />
      <motion.img
        className="absolute"
        src={square}
        style={{
          position: "absolute",
          top: "40%",
          left: "20%",
          transform: "translate(-50%, -50%)",
          zIndex: 5,
        }}
        animate={{ x: "50%" }}
        initial={{ x: "-100%", y: "-100%" }}
        transition={{
          duration: 4,
          repeat: Infinity,
          repeatType: "reverse",
          ease: "linear",
        }}
      />
      <motion.img
        className="absolute"
        src={tri}
        style={{
          position: "absolute",
          top: "10%",
          left: "30%",
          transform: "translate(-50%, -50%)",
          zIndex: 5,
        }}
        animate={{ x: "150%" }}
        initial={{ x: "-150%", y: "-100%" }}
        transition={{
          duration: 6,
          repeat: Infinity,
          repeatType: "reverse",
          ease: "linear",
        }}
      />

      <motion.img
        className="absolute"
        src={circle}
        style={{
          position: "absolute",
          top: "0%",
          left: "70%",
          transform: "translate(-50%, -50%)",
          zIndex: 5,
        }}
        initial={{ y: 200, x: -100 }}
        animate={{ y: 140, opacity: 1 }}
        transition={{
          duration: 8,
          repeat: Infinity,
          repeatType: "reverse",
          ease: "linear",
        }}
      />

      <motion.img
        className="absolute"
        src={tri}
        style={{
          position: "absolute",
          top: "10%",
          left: "70%",
          transform: "translate(-50%, -50%)",
          zIndex: 5,
        }}
        animate={{ y: "150%" }}
        initial={{ x: "-150%", y: "-150%" }}
        transition={{
          duration: 6,
          repeat: Infinity,
          repeatType: "reverse",
          ease: "linear",
        }}
      />

      <motion.img
        className="absolute"
        src={square}
        style={{
          position: "absolute",
          top: "40%",
          left: "70%",
          transform: "translate(-50%, -50%)",
          zIndex: 5,
        }}
        animate={{ x: "50%" }}
        initial={{ x: "-100%", y: "-100%" }}
        transition={{
          duration: 4,
          repeat: Infinity,
          repeatType: "reverse",
          ease: "linear",
        }}
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-8 lg:px-32 py-12">
        <div className="mb-12">
          <p className="text-base font-bold text-gray-600 text-center">
            At PBX Technologies Inc., we deliver expert solutions designed to
            enhance wireless networks and ensure reliable connectivity. Our
            specialized services cater to both existing infrastructure and new
            developments, providing the expertise needed for seamless operation
            and expansion.
          </p>
        </div>

        <div className="rounded-[22px] shadow-[0px_4px_20px_5px_#00000024] p-6 sm:p-10">
          <div className="mb-8">
            <div className="flex items-start gap-2 md:gap-6">
              <div
                className="origin-center rotate-45 sm:h-[50px] sm:w-[50px] h-[40px] w-[40px] bg-[#F3D337] sm:rounded-[20px] rounded-[15px]
                my-design before:border-[#F3D337] before:border-2 before:top-[0px] before:left-[5px] before:h-[2.8em]  before:sm:h-[3.4em] shrink-0"
              >
                <TbAntenna
                  className="absolute z-10 rotate-[315deg] top-[20%] sm:left-[25%] left-[20%]"
                  size={25}
                  color="#ffffff"
                />
              </div>
              <div>
                <h3 className="text-lg sm:text-xl font-bold mb-1">
                  Cellular Radio and Antenna Installation:
                </h3>
                <p className="text-sm sm:text-base text-gray-600">
                  We ensure robust wireless coverage with precise installation of
                  cellular radios and antennas, optimizing signal strength and
                  network reliability.
                </p>
              </div>
            </div>
          </div>

          <div className="mb-8">
            <div className="flex items-start gap-2 md:gap-6">
              <div
                className="origin-center rotate-45 sm:h-[50px] sm:w-[50px] h-[40px] w-[40px] bg-[#F3D337] sm:rounded-[20px] rounded-[15px]
                 my-design before:border-[#F3D337] before:border-2 before:top-[0px] before:left-[5px] before:h-[2.8em]  before:sm:h-[3.4em] shrink-0"
              >
                <LuRadioTower
                  className="absolute z-10 rotate-[315deg] top-[20%] sm:left-[25%] left-[20%]"
                  size={25}
                  color="#ffffff"
                />
              </div>
              <div>
                <h3 className="text-lg sm:text-xl font-bold mb-1">
                  Tower Reinforcing:
                </h3>
                <p className="text-sm sm:text-base text-gray-600">
                  Our services include reinforcing towers to improve stability,
                  ensuring long-term performance and safety.
                </p>
              </div>
            </div>
          </div>

          <div className="mb-8">
            <div className="flex items-start gap-2 md:gap-6">
              <div
                className="origin-center rotate-45 sm:h-[50px] sm:w-[50px] h-[40px] w-[40px] bg-[#F3D337] sm:rounded-[20px] rounded-[15px]
                 my-design before:border-[#F3D337] before:border-2 before:top-[0px] before:left-[5px] before:h-[2.8em]  before:sm:h-[3.4em] shrink-0"
              >
                <LuConstruction
                  className="absolute z-10 rotate-[315deg] top-[20%] sm:left-[25%] left-[20%]"
                  size={25}
                  color="#ffffff"
                />
              </div>
              <div>
                <h3 className="text-lg sm:text-xl font-bold mb-1">
                  New Site/Tower Construction:
                </h3>
                <p className="text-sm sm:text-base text-gray-600">
                  We handle new site and tower construction projects, facilitating
                  network expansion with efficient and reliable setups.
                </p>
              </div>
            </div>
          </div>

          <div className="mb-8">
            <div className="flex items-start gap-2 md:gap-6">
              <div
                className="origin-center rotate-45 sm:h-[50px] sm:w-[50px] h-[40px] w-[40px] bg-[#F3D337] sm:rounded-[20px] rounded-[15px]
                 my-design before:border-[#F3D337] before:border-2 before:top-[0px] before:left-[5px] before:h-[2.8em]  before:sm:h-[3.4em] shrink-0"
              >
                <GrInstall
                  className="absolute z-10 rotate-[315deg] top-[20%] sm:left-[25%] left-[20%]"
                  size={25}
                  color="#ffffff"
                />
              </div>
              <div>
                <h3 className="text-lg sm:text-xl font-bold mb-1">
                  Microwave Link Installation and Alignment:
                </h3>
                <p className="text-sm sm:text-base text-gray-600">
                  We provide high-capacity connections through expert microwave link
                  installation and alignment, ensuring dependable data
                  transmission.
                </p>
              </div>
            </div>
          </div>
          <div className="mb-8">
            <div className="flex items-start gap-2 md:gap-6">
              <div
                className="origin-center rotate-45 sm:h-[50px] sm:w-[50px] h-[40px] w-[40px] bg-[#F3D337] sm:rounded-[20px] rounded-[15px]
                 my-design before:border-[#F3D337] before:border-2 before:top-[0px] before:left-[5px] before:h-[2.8em]  before:sm:h-[3.4em] shrink-0"
              >
                <GrHostMaintenance
                  className="absolute z-10 rotate-[315deg] top-[20%] sm:left-[25%] left-[20%]"
                  size={25}
                  color="#ffffff"
                />
              </div>
              <div>
                <h3 className="text-lg sm:text-xl font-bold mb-1">
                  Emergency Maintenance:
                </h3>
                <p className="text-sm sm:text-base text-gray-600">
                  Our team is available for emergency maintenance to quickly address
                  and resolve critical issues, keeping your network operational.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WirelessFeatures;
