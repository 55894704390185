import React, { useEffect } from 'react'
import Layout from "../../components/Layout/Layout";
import ContactUs from './form';
import './index.css'

export default function Contact () {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[Contact])
  return (
    <Layout>
      <div className="relative ">
        <div className="row flex about-us justify-center bg-[#080c3c] ">
          <h3 className="font-semibold font-serif  text-my-headingT text-white py-[30px]">
            Contact With Us
          </h3>
       </div>
      <ContactUs/>
      <div className="w-full">
            <iframe className="w-full" height="510" id="gmap_canvas" title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2886.5405304634405!2d-79.64870652392167!3d43.65772697110182!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b38d3b8f6b079%3A0x9e8e053772c38d13!2s1585%20Britannia%20Rd%20E%2C%20Mississauga%2C%20ON%20L4W%202M4%2C%20Canada!5e0!3m2!1sen!2sin!4v1689567123621!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ></iframe>
        </div>
        </div>
    </Layout>
  )
}
